const tenant_settings = {
    title: 'Ustawienia',
    description: 'Zmieniaj swoje ustawienia konta i zarządzaj swoimi informacjami osobistymi tutaj, aby zapewnić bezpieczeństwo Twojego konta.',
    tabs: {
        settings: 'Ustawienia',
        domains: 'Domeny',
    },
    profile: {
        title: 'USTAWIENIA PROFILU',
        tenant_id: 'ID Najemcy',
        tenant_name: 'Nazwa Najemcy',
        environment_tag: 'Tag Środowiska',
        environment_tag_description: 'Usługi z różnymi tagami są identyczne. Działa jako przyrostek, aby pomóc Twojemu zespołowi w różnicowaniu środowisk.',
        environment_tag_development: 'Dev',
        environment_tag_staging: 'Staging',
        environment_tag_production: 'Prod',
        tenant_info_saved: 'Informacje o najemcy zostały pomyślnie zapisane.',
    },
    deletion_card: {
        title: 'USUWANIE',
        tenant_deletion: 'Usuń najemcę',
        tenant_deletion_description: 'Usunięcie twojego konta spowoduje usunięcie wszystkich twoich danych osobowych, danych użytkownika i konfiguracji. Ta operacja jest nieodwracalna.',
        tenant_deletion_button: 'Usuń najemcę',
    },
};
export default tenant_settings;
