const tenant_settings = {
    title: 'Einstellungen',
    description: 'Ändern Sie Ihre Kontoeinstellungen und verwalten Sie hier Ihre persönlichen Informationen, um die Sicherheit Ihres Kontos zu gewährleisten.',
    tabs: {
        settings: 'Einstellungen',
        domains: 'Domänen',
    },
    profile: {
        title: 'PROFIL-EINSTELLUNG',
        tenant_id: 'Mieter-ID',
        tenant_name: 'Mietername',
        environment_tag: 'Umgebungsmarke',
        environment_tag_description: 'Die Dienste mit unterschiedlichen Tags sind identisch. Es funktioniert als Suffix, um Ihrem Team Umgebungen zu unterscheiden.',
        environment_tag_development: 'Dev',
        environment_tag_staging: 'Staging',
        environment_tag_production: 'Prod',
        tenant_info_saved: 'Mieterinformationen erfolgreich gespeichert.',
    },
    deletion_card: {
        title: 'LÖSCHEN',
        tenant_deletion: 'Mieter löschen',
        tenant_deletion_description: 'Wenn Sie Ihr Konto löschen, werden alle persönlichen Informationen, Benutzerdaten und Konfigurationen entfernt. Dieser Vorgang kann nicht rückgängig gemacht werden.',
        tenant_deletion_button: 'Mieter löschen',
    },
};
export default tenant_settings;
